<template>
    <div class="inviteContent">
        <div class="mask" v-if="isMaskVisible">
            <!-- 加载动画start -->
            <loadAction :loading="isLoad" />
            <!-- 加载动画end --> 
        </div>
        <div class="inviteContent_search">
            <el-input v-model="inputUser" placeholder="请输入用户名,邮箱或卡密"></el-input>
            <el-button type="primary" @click="getList(1)">查询</el-button>
        </div>
        <div class="inviteContent_table">
            <tablelist :tableArray="inviteList" :defaultTable="defaultTable" @currentMethod="currentMethod"
                :isPage="true" :total="total" :pageSize="pageSize"></tablelist>
        </div>
    </div>
</template>
<script>
    import api from '../../http/api';
    export default {
        data() {
            return {
                isMaskVisible:true,
                isLoad: true,  //加载动画
                inputUser: '',//用户名
                total: 0, //总页数
                pageSize: 40, //分页
                pageIndex: 1, //当前页码
                defaultTable: [],
                inviteList: [
                    {
                        key: "user_name",
                        name: "用户名",
                        width: "20%",
                    },
                    {
                        key: "email",
                        name: "邮箱",
                        width: "10%",
                    },
                    {
                        key: "create_time",
                        name: "注册日期",
                        width: "10%",
                    },
                    {
                        key: "state",
                        name: "当前状态",
                        width: "8%",

                    },
                    // {
                    //     key: "isDue",
                    //     name: "是否到期",
                    //     width: "8%",
                    // },
                ]
            }
        },
        components: {
            loadAction: () => import("../components/load"),
            tablelist: () => import("../components/table")
        },
        activated() {
            this.isLoad =true
            this.inputUser=''
            this.isMaskVisible = true
            this.getList();
        },
        methods: {
            getList(data) {
                let that = this;
                that.pageIndex=data?data:that.pageIndex
                api.getInviteList({
                    user: that.inputUser,
                    page_index:that.pageIndex,
                    page_size:that.pageSize
                }).then(res => {
                    //console.log('getInviteList--->', res)
                    let stateList = {
                        0: '未充值',
                        1: '已充值',
                        2: '已到期',
                        3: '无余额',
                    }
                    if (res.code == 200) {
                        that.total = res.response.total;
                        that.defaultTable = res.response.data.map(item => {
                            return {
                                ...item,
                                state: stateList[item.type]
                            }
                        })
                    }
                    setTimeout(() => {
                        this.isMaskVisible = false;
                        this.isLoad = false;
                    }, 200);
                })
            },
            currentMethod(index) {
                this.getList(index);
            },
        }
    }
</script>
<style lang="less" scoped>
    .inviteContent {
        width: 100%;
        height: 100%;
    }

    .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(255, 255, 255, 0.98); /* 半透明黑色背景 */
        z-index: 1000; /* 确保它在其他元素之上 */
   }

    /deep/ .inviteContent_search .el-input {
        width: 300px;
    }

    /deep/ .inviteContent_search .el-input__inner {
        height: 35px;
        line-height: 35px;
        border-radius: 0;
    }

    /deep/ .inviteContent_search .el-button {
        padding: 10px 20px;
        border-radius: 0px;
        margin-left: 10px;
        background: #0B41D7 !important;
        border-color: #0B41D7 !important;
    }

    .inviteContent_table {
        height: calc(100% - 50px);
        margin-top: 10px;
    }
</style>